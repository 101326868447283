<template>
  <div class="relative min-h-screen f-col">
    <template v-if="!hideLayout">
      <vf-button
        v-if="mainContent"
        variant="primary-inverse"
        size="tiny"
        to="#main-content"
        class="absolute left-0 top-0 ml-4"
        style="z-index: 10000"
        :style="{ 'margin-top': showSkipLink ? '0.25rem' : '-100%' }"
        data-test-id="skip-to-main-content"
        @click="mainContent.focus()"
        @focus="showSkipLink = true"
        @blur="showSkipLink = false"
      >
        {{ $t.skipToMainContent }}
      </vf-button>

      <suspense>
        <vf-header-provider />
      </suspense>

      <div
        id="main-content"
        ref="mainContent"
        class="relative"
        tabindex="-1"
        style="top: -5rem; margin-top: -1px"
      />
    </template>

    <slot />

    <template v-if="!hideLayout">
      <base-lazy-hydrate when="visible">
        <vf-footer ref="footerRef" class="mt-a" />
      </base-lazy-hydrate>
    </template>
  </div>
</template>

<script lang="ts" setup>
const footerRef = useState<HTMLElement>('footerRef')
const route = useRoute()

const mainContent = ref<HTMLDivElement>()
const showSkipLink = ref(false)

const hideLayout = computed(() => route.meta.hideLayout === true)
</script>
